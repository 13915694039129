@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800;900&family=Poppins:wght@700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.link:hover {
  background-color: #073b41;
  color: white;
}
.page-item.active {
  background-color: #073b41;
  color: white;
  border-radius: 6px;
}

/* The switch - the box around the slider */

/* Adjust as needed to add spacing between image and text */

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888;
  border-radius: 5px; */
  visibility: hidden;
}

/* ConfirmationPopup.css */
.confirmation-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  border: none;
  border-color: white;
  width: 100%;
}
/* Styling for the modal content */
.modal-content {
  margin-top: 300px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 50px 20px 50px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

/* Styling for the modal buttons */
.modal-buttons {
  margin-top: 40px;
  display: flex;
  gap: 10px;
  cursor: pointer;
}

/* Styling for the cancel button */
.modal-buttons button.cancel-btn {
  padding: 8px 16px;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Styling for the confirm button */
.modal-buttons button.confirm-btn {
  padding: 8px 16px;
  background-color: #f44336;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: yellow; */
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 100px;
  border: 1px solid rgba(71, 93, 91, 0.1);
  background: var(--f-2-f-7-f-5, #f2f7f5);
}
.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.on {
  background-color: yellow; /* Color when 'on' */
}

.slider.off {
  background-color: blue; /* Color when 'off' */
}

input:checked + .slider {
  /* background-color: #2ab934; */
}

input:focus + .slider {
  /* box-shadow: 0 0 1px #2196F3; */
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on,
.off {
  color: #475d5b;
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 10px;
  font-family: Verdana, sans-serif;
}
.on {
  top: 50%;
  left: 30%;
  font-weight: 500;
}
.off {
  top: 50%;
  left: 70%;
  font-weight: 500;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* .blurred-background {
  filter: blur(4px); 
  pointer-events: none; 
} */

/* .container {
  width: 100%;
  height: 100vh;
} */
/* #popup {
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  padding: 50px;
}
#popup.active {
  visibility: visible;
  transition: 0.5s;
} */
/* .container#blur.active {
  filter: blur(20px);
  pointer-events: none;
} */

.blurred-background {
  opacity: 0.5;
  background: #000;
  pointer-events: none;
}

.popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  margin-top: 60px;
  margin-left: 15px;
}

table {
  border-radius: var(--cui-border-radius) !important;
  overflow: hidden !important;
}
table tr:first-child th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
table tr:first-child th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
